<template>
  <div class="container">
    <div class="content">
      <div class="head">
        <div class="head-title">xinqu.ai</div>
        <div class="download-buttons">
          <template :key="item.key" v-for="item in downloadList">
            <div class="download-item">
              <div class="download-icon" :class="item.key"></div>
              {{item.text}}
            </div>
          </template>
        </div>
      </div>
      <div class="bottom-container">
        <div class="main-container">
          <h1 class="title">小西: 你的AI英语老师</h1>
          <h2 class="subtitle">探索全新英语口语学习！个性化、实时反馈的AI智能助手，助你流利对话。随时随地对话学习。超越传统，快速提升英语口语能力！</h2>
          <!--<div class="main-button">Continue</div>-->
        </div>
        <div class="footer">
          <div class="footer-copyright">
            <span class="copyright">Copyright @ 杭州新趋科技有限公司</span>
            <a href="https://beian.miit.gov.cn/#/Integrated/index" class="record">浙ICP备2023019762号</a>
          </div>
          <div class="footer-links">
            <div class="footer-links-item">
              <a href="mailto:contact@xinquai.com">联系我们</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data:function() {
    return {
      downloadList:[
        {
          key:'Android',
          text:'Android'
        },
        {
          key:'IOS',
          text:'IOS'
        },
      ],
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container{
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-image: url('https://xinquai-1317364716.cos.ap-guangzhou.myqcloud.com/user_upload/3281708308895.09075476dc64aa228635ddd4b063622637796ad.jpg');
}
.content{
  z-index: 1;
  width: 100%;
  max-width: 1360px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 50px;
  box-sizing: border-box;
}
.head{
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  display: flex;
}
.head-title{
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  font-family: Roboto,sans-serif;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-size: 28px;
}

.download_buttons{
  flex-direction: column;
  display: flex;
}

.download-item{
  color: #fff;
  border: 1px solid rgba(255,255,255,.5);
  border-radius: 100px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 132px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
}

.Android{
  background: url('https://xinquai-1317364716.cos.ap-guangzhou.myqcloud.com/user_upload/3281708308946.7160745f229d0e1a5cc54c44f96a84d7a6138ec.png');
  background-size: cover;
}

.IOS{
  background: url('https://xinquai-1317364716.cos.ap-guangzhou.myqcloud.com/user_upload/3281708308930.36706024ff40ca29ba0bd35d8228f5c254bbdfc.png');
  background-size: cover;
}

.download-icon{
  width: 28px;
  height: 28px;
  background-size: cover;
  margin-right: 8px;
}
.bottom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.main-container{
  display: flex;
  color: #fff;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  margin-bottom: 100px;
  font-family: Roboto,sans-serif;
}
.title {
  font-size: 50px;
  font-weight: 600;
}
.subtitle {
  margin-top: 16px;
  margin-bottom: 36px;
  font-weight: 300;
  color: #fff;
}

.main-button {
  width: 250px;
  background: #fff;
  padding: 8px 24px;
  box-sizing: border-box;
  color: #000;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
}
.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}

.footer-copyright {
  color: #fff;
  opacity: .5;
}

.copyright{
  transition: color .2s ease;
  color: hsla(0,0%,100%);
}

.record{
  margin-left: 20px;
  transition: color .2s ease;
  color: hsla(0,0%,100%);
}

.footer-links{
  display: flex;
}
.footer-links-item {
    transition: color .2s ease;
    color: hsla(0,0%,100%);
    margin-right: 32px;
    font-weight: 300;
}
.footer-links-item a{
  transition: color .2s ease;
  color: hsla(0,0%,100%);
}
</style>
